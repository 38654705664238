import { Builder } from "@tjaara/themebuilder/dist/index.module.js";
import { sidebarToggle } from "helpers/functions";
import React from "react";
import { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useParams, useSearchParams } from "react-router-dom";
import {
  getThemePages,
  getThemeComponentsType,
  getThemeFonts,
  getTheme,
  getSettings,
  editThemeComponent,
  editThemeSettings,
  editThemeCss,
  editStoreSettings,
  getProducts,
  getBlogs,
  getProductCategories,
} from "store/actions";

const Edit = () => {
  const dispatch = useDispatch();
  const { themeId } = useParams();
  const [urlSearchParams] = useSearchParams();
  const deviceType = urlSearchParams.get("deviceType");

  const store = localStorage.getItem("store");

  useEffect(() => {
    dispatch(getBlogs());
    dispatch(getProductCategories());
    dispatch(getProducts());
  }, [dispatch]);

  useEffect(() => {
    dispatch(getThemePages(deviceType));
    dispatch(getThemeComponentsType(deviceType));
    dispatch(getThemeFonts());
    dispatch(getTheme({ storeId: store, themeId }));
    dispatch(getSettings("basicSettings"));
  }, [deviceType, dispatch, store, themeId]);

  const { theme, themePages, themeComponentsType, themeFonts } = useSelector(
    (state) => state.builder
  );
  const { settings } = useSelector((state) => state.settings);
  const { blogs } = useSelector((state) => state.blogs);
  const { products } = useSelector((state) => state.products);
  const { categories } = useSelector((state) => state.productCategories);

  useEffect(() => {
    const fullUrl = window.location.href;
    const isBuilder = fullUrl.includes("builder");
    if (isBuilder) {
      sidebarToggle();
    }
  }, []);

  return (
    <>
      <div className="edit-theme">
        <Builder
          theme={theme}
          themePages={themePages}
          themeComponentsType={themeComponentsType}
          themeFonts={themeFonts}
          storeSettings={settings}
          websiteUrl={`${process.env.REACT_APP_FRONT_WEBSITE}/`}
          backendUploadUrl={process.env.REACT_APP_API_URL}
          dispatch={dispatch}
          editThemeComponent={editThemeComponent}
          editThemeSettings={editThemeSettings}
          editStoreSettings={editStoreSettings}
          editThemeCss={editThemeCss}
          blogs={blogs}
          products={products}
          categories={categories}
        />
      </div>
    </>
  );
};

export default Edit;
