import BreadCrumb from "components/shared/BreadCrumb";
import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate, useParams } from "react-router-dom";
import { getCustomersTags, getOrders, getSingleCustomer } from "store/actions";
import Loader from "../../components/shared/Loader";
import user from "assets/svgs/order-employee.svg";
import { ReactComponent as WhatsappIcon } from "assets/svgs/whatsapp.svg";
import { ReactComponent as Phone } from "assets/svgs/call.svg";
import { ReactComponent as Email } from "assets/svgs/mail.svg";
import { ReactComponent as Calendar } from "assets/svgs/order-date.svg";
import { ReactComponent as SmsIcon } from "assets/svgs/sms.svg";
import productsIcon from "assets/svgs/products.svg";
import { Col, Dropdown, Row } from "react-bootstrap";
import { FormattedMessage, useIntl } from "react-intl";
import { getId } from "helpers/functions";
import CustomerOrdersTable from "components/customers/CustomerOrdersTable";
import userImg from "../../assets/images/user.png";
const ShowCustomer = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { formatDate, formatMessage } = useIntl();
  const { id } = useParams();

  useEffect(() => {
    dispatch(getOrders());
    dispatch(getSingleCustomer(id));
    dispatch(getCustomersTags());
  }, [dispatch, id]);

  const { orders, loading: ordersLoading } = useSelector(
    (state) => state.orders
  );
  const { singleUser, loading } = useSelector((state) => state.customers);
  console.log("singleUser", singleUser?.user);

  const handleEditUser = () => {
    navigate(`/customers/edit/${id}`);
  };

  // const hashedPhone = `${String(singleUser?.phone)?.slice(
  //   0,
  //   4
  // )}${singleUser?.phone
  //   ?.slice(4, singleUser?.phone?.length)
  //   ?.replaceAll(/\d/g, "#")}`;

  if (loading || ordersLoading) return <Loader />;

  return (
    <div className="customer-show">
      <div className="acc-form">
        <BreadCrumb pageName={formatMessage({ id: "customers" })} />

        <div className="orderBlocks">
          <Row>
            <Col xs={12}>
              <div className="card block customer">
                <div className="c-head">
                  <span>
                    <img src={user} alt="" />
                    العـميل
                  </span>
                  <span>
                    <Dropdown>
                      <Dropdown.Toggle>
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          width="20"
                          height="20"
                          fill="currentColor"
                          className="bi bi-three-dots-vertical"
                          viewBox="0 0 16 16"
                        >
                          <path d="M9.5 13a1.5 1.5 0 1 1-3 0 1.5 1.5 0 0 1 3 0zm0-5a1.5 1.5 0 1 1-3 0 1.5 1.5 0 0 1 3 0zm0-5a1.5 1.5 0 1 1-3 0 1.5 1.5 0 0 1 3 0z" />
                        </svg>
                      </Dropdown.Toggle>

                      <Dropdown.Menu>
                        <Dropdown.Item
                          onClick={(e) => handleEditUser(getId(singleUser))}
                        >
                          <FormattedMessage id="editCustomer" />
                        </Dropdown.Item>
                      </Dropdown.Menu>
                    </Dropdown>
                  </span>
                </div>
                <div className="c-body">
                  <div className="userImg">
                    <img
                      src={
                        singleUser?.user?.image
                          ? singleUser?.user?.image
                          : userImg
                      }
                      alt=""
                    />

                    <h4 className="mt-3">{singleUser?.user?.username}</h4>
                    <p>
                      {[
                        formatDate(singleUser?.birthDate, {
                          year: "numeric",
                          month: "numeric",
                          day: "numeric",
                        }),
                        singleUser?.gender,
                      ]
                        ?.filter((e) => !!e)
                        ?.join(" - ")}
                    </p>
                  </div>

                  <div className="userDetails">
                    {singleUser?.user?.phone && (
                      <a
                        href={`tel:${singleUser?.user?.phone}`}
                        className="point"
                        target="_blank"
                        rel="noreferrer"
                      >
                        <Phone />
                        <p>
                          {`${singleUser?.user?.phone}`}
                          {/* {`${singleUser?.user?.phone}`?.slice(0, 4)}
                        {`${singleUser?.user?.phone}`
                          ?.slice(4, singleUser?.user?.phone?.length)
                          ?.replaceAll(/\d/g, "#")} */}
                        </p>
                      </a>
                    )}

                    {/* <a
                      href={`mailto:${singleUser?.user?.email}`}
                      className="point"
                      target="_blank"
                      rel="noreferrer"
                    >
                      <Email />
                      <p>{singleUser?.user?.email}</p>
                    </a> */}
                    <div className="point">
                      <Calendar />
                      <FormattedMessage id="joiningDate" />
                      {formatDate(singleUser?.createdAt, {
                        year: "numeric",
                        month: "numeric",
                        day: "numeric",
                      })}
                    </div>
                    <div className="userSocial">
                      <ul>
                        <li>
                          {singleUser?.user?.phone && (
                            <a
                              href={`https://wa.me/${singleUser?.user?.phone}`}
                              target="_blank"
                              rel="noreferrer"
                              className="point small"
                            >
                              <WhatsappIcon />
                              <FormattedMessage id="whatsapp" />
                            </a>
                          )}
                        </li>
                        <li>
                          <a
                            href="#sms"
                            target="_blank"
                            rel="noreferrer"
                            className="point small"
                          >
                            <SmsIcon />
                            <FormattedMessage id="sms" />
                          </a>
                        </li>
                        <li>
                          {singleUser?.user?.email && (
                            <a
                              href={`mailto:${singleUser?.user?.email}`}
                              className="point small"
                            >
                              <Email />
                              <FormattedMessage id="email" />
                            </a>
                          )}
                        </li>
                      </ul>
                    </div>
                  </div>
                </div>
              </div>
            </Col>
          </Row>
          <Col xxl={12} xl={12} lg={12} md={12} sm={12}>
            <div className="card block">
              <div className="c-head">
                <span>
                  <img src={productsIcon} alt="" />
                  <FormattedMessage id="orders" />
                </span>
                {/* <div className="ordersServices">
                    <button className="btn btn-blue">
                      <img src={print} alt="" />
                      طباعة
                    </button>
                  </div> */}
              </div>
              <div className="c-body">
                <CustomerOrdersTable
                  data={singleUser?.orders || orders}
                  showHead={false}
                />
              </div>
            </div>
          </Col>
        </div>
      </div>
    </div>
  );
};

export default ShowCustomer;
