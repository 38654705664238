import React, { useMemo, useState, forwardRef, useEffect } from "react";
import DataTable from "react-data-table-component";
import { Col, Dropdown, Modal, Form, Button } from "react-bootstrap";

import Filter from "components/shared/Filter";
import { useDispatch, useSelector } from "react-redux";
import {
  addCustomerToGroup,
  blockCustomer,
  deleteCustomer,
  deleteCustomerFromGroup,
  getCustomers,
} from "store/actions";
import Loader from "components/shared/Loader";
import { Link, useNavigate } from "react-router-dom";
import { getId } from "helpers/functions";
import { FormattedMessage } from "react-intl";
import { ReactComponent as DeleteIcon } from "assets/svgs/product/trash.svg";
import { ReactComponent as PencilIcon } from "assets/svgs/pencil.svg";
import { ReactComponent as PersonRemoveIcon } from "assets/svgs/person-remove.svg";
import { ReactComponent as PersonAddIcon } from "assets/svgs/person-add.svg";
import { ReactComponent as PersonBlockIcon } from "assets/svgs/person-block.svg";
import { ReactComponent as PeopleIcon } from "assets/svgs/people.svg";
import { Controller, useForm } from "react-hook-form";
import Select from "react-select";
import { useIntl } from "react-intl";
import Swal from "sweetalert2";

// import { deleteReport } from "../../store/actions";
const Table = (props) => {
  const { locale, formatMessage } = useIntl();
  const dispatch = useDispatch();
  let navigate = useNavigate();

  const { groups } = useSelector((state) => state?.groups);

  const [pending, setPending] = useState(true);
  const [rows, setRows] = useState([]);
  const [showGroupsModal, setShowGroupsModal] = useState(false);
  const [selectedCustomer, setSelectedCustomer] = useState({});
  const [deleteFlag, setDeleteFlag] = useState(false);

  const { handleSubmit, control } = useForm();

  useEffect(() => {
    const timeout = setTimeout(() => {
      setRows(rows);
      setPending(false);
    }, 2000);
    return () => clearTimeout(timeout);
  }, [dispatch, rows]);

  const BootyCheckbox = new forwardRef(({ onClick, ...rest }, ref) => (
    <div className="form-check">
      <input
        htmlFor="booty-check"
        type="checkbox"
        className="form-check-input"
        ref={ref}
        onClick={onClick}
        {...rest}
      />
      <label className="form-check-label" id="booty-check" />
    </div>
  ));

  const [filterText, setFilterText] = useState("");
  const [resetPaginationToggle, setResetPaginationToggle] = useState(false);

  const filteredItems = props?.data?.filter(
    (item) =>
      JSON.stringify(item).toLowerCase().indexOf(filterText.toLowerCase()) !==
      -1
  );

  const subHeaderComponent = useMemo(() => {
    const handleClear = () => {
      if (filterText) {
        setResetPaginationToggle(!resetPaginationToggle);
        setFilterText("");
      }
    };

    return (
      <Filter
        onFilter={(e) => setFilterText(e.target.value)}
        onClear={handleClear}
        filterText={filterText}
      />
    );
  }, [filterText, resetPaginationToggle]);

  const handleEditUser = (id) => {
    navigate(`/customers/edit/${id}`);
  };

  const handleBlockUser = (data) => {
    Swal.fire({
      title: `${formatMessage({
        id: data?.isBlocked ? "unblockCustomer" : "blockCustomer",
      })} (${data?.username})`,
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "#2a89f3",
      cancelButtonColor: "#d33",
      confirmButtonText: "نعم",
      cancelButtonText: "لا",
    }).then((result) => {
      if (result.isConfirmed) {
        dispatch(blockCustomer(getId(data)));
      }
    });
  };

  const handleDeleteUser = (data) => {
    Swal.fire({
      title: `${formatMessage({ id: "deleteCustomer" })} (${data?.username})`,
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "#2a89f3",
      cancelButtonColor: "#d33",
      confirmButtonText: "نعم",
      cancelButtonText: "لا",
    }).then((result) => {
      if (result.isConfirmed) {
        dispatch(deleteCustomer(getId(data)));
      }
    });
  };

  const handleAddToGroup = (customer) => {
    setShowGroupsModal(true);
    setDeleteFlag(false);
    let customer1 = { ...customer };
    customer1.groups = customer1?.groups?.map((e) => getId(e?.group)) || [];
    setSelectedCustomer(customer1);
  };
  const handleRemoveFromGroup = (customer) => {
    setShowGroupsModal(true);
    setDeleteFlag(true);
    let customer1 = { ...customer };
    customer1.groups = customer1?.groups?.map((e) => getId(e?.group)) || [];
    setSelectedCustomer(customer1);
  };

  const handleClose = () => {
    setShowGroupsModal(false);
    setDeleteFlag(false);
    setSelectedCustomer({});
  };

  const getOptionsByName = (arr) =>
    // [
    //   {
    //     value: undefined,
    //   },
    // ].concat(
    arr?.map((item) => ({
      label: item.name?.[locale],
      value: getId(item),
    }));
  // );

  const onSubmit = (data) => {
    const id = getId(selectedCustomer);
    const action = deleteFlag ? deleteCustomerFromGroup : addCustomerToGroup;
    dispatch(
      action({
        data,
        id,
        callBack: () => setShowGroupsModal(false),
      })
    );
  };

  const renderCustomerGroups = (arr) =>
    (arr || [])?.map((e) => (
      <span className="point">
        {e?.group?.icon ? <img src={e?.group?.icon} alt="" /> : <PeopleIcon />}
        {e?.group?.name?.[locale]}
      </span>
    ));

  const columns = [
    {
      name: "الاسم",
      selector: (row) => (
        <div className="customer-name">
          <Link to={`/customers/show/${getId(row)}`}>{row?.username?.length > 50 ? `${row?.username?.slice(0,50)}...` : row?.username}</Link>
          <div className="customer-groups">
            {renderCustomerGroups(row?.groups)}
            {row?.isBlocked ? (
              <span className="point blocked">
                <PersonBlockIcon fill={"#e74c3c"} />
              </span>
            ) : null}
          </div>
        </div>
      ),
      sortable: true,
      maxWidth: "600px"
    },
    {
      name: "البريد الالكتروني",
      selector: (row) => row?.email,
      sortable: true,
      maxWidth: "400px"
    },
    {
      name: "رقم الجوال",
      selector: (row) => row?.phone,
      sortable: true,
      maxWidth: "200px"
    },
    {
      cell: (row) => (
        <div className="global-dropdown">
          <Dropdown>
            <Dropdown.Toggle>
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="20"
                height="20"
                fill="currentColor"
                className="bi bi-three-dots-vertical"
                viewBox="0 0 16 16"
              >
                <path d="M9.5 13a1.5 1.5 0 1 1-3 0 1.5 1.5 0 0 1 3 0zm0-5a1.5 1.5 0 1 1-3 0 1.5 1.5 0 0 1 3 0zm0-5a1.5 1.5 0 1 1-3 0 1.5 1.5 0 0 1 3 0z" />
              </svg>
            </Dropdown.Toggle>

            <Dropdown.Menu>
              <Dropdown.Item onClick={() => handleEditUser(getId(row))}>
                <PencilIcon />
                <FormattedMessage id="edit" />
              </Dropdown.Item>
              <Dropdown.Item onClick={() => handleAddToGroup(row)}>
                <PersonAddIcon width={20} />
                <FormattedMessage id="addToGroup" />
              </Dropdown.Item>
              <Dropdown.Item onClick={(e) => handleRemoveFromGroup(row)}>
                <PersonRemoveIcon />
                <FormattedMessage id="removeFromGroup" />
              </Dropdown.Item>
              <Dropdown.Item onClick={(e) => handleBlockUser(row)}>
                <PersonBlockIcon />
                <FormattedMessage
                  id={row?.isBlocked ? "unblockCustomer" : "blockCustomer"}
                />
              </Dropdown.Item>
              <Dropdown.Item onClick={() => handleDeleteUser(row)}>
                <DeleteIcon />
                حذف
              </Dropdown.Item>
            </Dropdown.Menu>
          </Dropdown>
        </div>
      ),
      allowOverflow: true,
      button: true,
      width: "56px",
    },
  ];

  return (
    <>
      <div className="table-wrap">
        <div className="card">
          <Col xl={6} md={12} xs={12}>
            <div className="card-head">
              <div>
                <h4>
                  <FormattedMessage id="customerTableData" />{" "}
                </h4>
              </div>
            </div>
          </Col>
          <div className="card-body">
            <DataTable
              columns={columns}
              data={filteredItems}
              progressPending={pending}
              progressComponent={<Loader />}
              defaultSortField="name"
              selectableRows
              
              // subHeader
              subHeaderComponent={subHeaderComponent}
              selectableRowsComponent={BootyCheckbox}
            />
          </div>
        </div>
        <Modal show={showGroupsModal} onHide={handleClose}>
          <Modal.Header>
            <Modal.Title>
              <FormattedMessage
                id={
                  deleteFlag ? "removeCustomerFromGroup" : "addCustomerToGroup"
                }
                values={{ name: selectedCustomer?.username }}
              />
            </Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <form onSubmit={handleSubmit(onSubmit)}>
              <Form.Group className="mb-3 col-12">
                <Form.Label>
                  <FormattedMessage id="selectGroup" />
                </Form.Label>
                <Controller
                  control={control}
                  name={`group`}
                  rules={{ required: true }}
                  render={({ field: { onChange, value, selected } }) => (
                    <Select
                      isRtl={true}
                      isSearchable={false}
                      className="basic-single"
                      classNamePrefix="select"
                      options={getOptionsByName(
                        // deleteFlag
                        // ?
                        groups?.filter((g) =>
                          deleteFlag
                            ? selectedCustomer?.groups?.includes(getId(g))
                            : !selectedCustomer?.groups?.includes(getId(g))
                        )
                        // : groups
                      )}
                      placeholder={formatMessage({ id: "selectGroup" })}
                      isMulti={false}
                      value={getOptionsByName(groups)?.find(
                        (o) => o?.value === value
                      )}
                      onChange={({ value }) => {
                        onChange(value);
                      }}
                    />
                  )}
                />
              </Form.Group>
              <Modal.Footer>
                <Button variant="secondary" onClick={handleClose}>
                  <FormattedMessage id="cancel" />
                </Button>
                <Button variant="primary" type="submit">
                  <FormattedMessage id={deleteFlag ? "remove" : "add"} />
                </Button>
              </Modal.Footer>
            </form>
          </Modal.Body>
        </Modal>
      </div>
    </>
  );
};

export default Table;
