import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Dropdown } from "react-bootstrap";
import {
  getAreas,
  getCities,
  getCountries,
  getCustomers,
  getCustomersTags,
  getGroups,
} from "store/actions";
import * as XLSX from "xlsx";
import BreadCrumb from "components/shared/BreadCrumb";
import { ReactComponent as SettingIcon } from "assets/svgs/Setting.svg";
import filterIcon from "assets/svgs/filter.svg";
import { ReactComponent as SearchIcon } from "assets/svgs/search.svg";
import Papa from "papaparse";
import { FormattedMessage, useIntl } from "react-intl";
import StickyLoader from "components/shared/StickyLoader";
import BootyPagination from "components/shared/Pagination";
import ParamsFilters from "components/shared/ParamsFilters";
import Table from "./Table";
import { handleIsoDate } from "helpers/functions";
import GroupsList from "components/customers/GroupsList";
import CustomersFilterSidebar from "components/customers/CustomersFilterSidebar";
import useSearchFormParam from "components/shared/SearchHook";
import { useNavigate } from "react-router-dom";

const Customers = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { formatMessage, formatDate, locale } = useIntl();
  const [registerSearch, handleSubmitSearch, searchParams, EmptyButton] =
    useSearchFormParam({
      paramName: "searchText",
    });
  const [xlsxData, setXlsxData] = useState([]);

  const { users, metadata, countries, cities, areas, loading } = useSelector(
    (state) => state.customers
  );
  const { groups } = useSelector((state) => state.groups);

  const tags = [...new Set(users?.flatMap((user) => user?.tags || []) || [])];

  const filterToggle = () => {
    document.querySelector(".ordersFilterSidebar")?.classList.toggle("active");
    document.querySelector(".overlay-g")?.classList.toggle("active");
  };

  useEffect(() => {
    searchParams.delete("group");
    searchParams.delete("country");
    searchParams.delete("city");
    const paginationQuery = `?page=${searchParams.get("page") ?? "1"}&limit=${
      searchParams.get("limit") ?? "20"
    }`;
    dispatch(getCustomers(paginationQuery));
  }, [dispatch, searchParams]);

  useEffect(() => {
    dispatch(getGroups());
    dispatch(getCountries());
    dispatch(getCities());
    dispatch(getAreas());
  }, [dispatch]);

  useEffect(() => {
    // fetchMetaData();
    if (users?.length > 0)
      setXlsxData(
        users.map((user) => {
          return {
            [formatMessage({ id: "createdAt" })]: handleIsoDate(
              user?.createdAt
            ),
            [formatMessage({ id: "username" })]: user?.username,
            [formatMessage({ id: "email" })]: user?.email,
            [formatMessage({ id: "phone" })]: user?.phone,
            // [formatMessage({ id: "ordersNum" })]: user?.orders?.length || 0,
            [formatMessage({ id: "isVerified" })]: formatMessage({
              id: user?.isVerified || " ",
            }),
            [formatMessage({ id: "gender" })]: user?.gender,
            [formatMessage({ id: "groups" })]: (user?.groups || [])
              ?.map((group) => group?.group?.name[locale])
              ?.join(", "),
            [formatMessage({ id: "birthDate" })]: handleIsoDate(
              user?.birthDate
            ),
            [formatMessage({ id: "tags" })]: user?.tags,
          };
        })
      );
  }, [formatMessage, locale, users]);

  // const fetchMetaData = async () => {
  //   const response = await server().get(`/users`);
  //   setMetadata(response.data.data.metadata);
  //   return response.data;
  // };

  const exportToXLSX = () => {
    const worksheet = XLSX.utils.json_to_sheet(xlsxData);
    const workbook = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(workbook, worksheet, "Sheet1");

    XLSX.writeFile(
      workbook,
      `customers-${formatDate(new Date(), {
        year: "numeric",
        month: "numeric",
        day: "numeric",
      })}.xlsx`
    );
  };

  const exportToCSV = () => {
    // Ensure data is an array of objects with keys representing column headers
    if (
      !Array.isArray(xlsxData) ||
      xlsxData.length === 0 ||
      typeof xlsxData[0] !== "object"
    ) {
      console.error("Invalid data format for CSV export");
      return;
    }

    // Convert data to a CSV string
    const csv = Papa.unparse(xlsxData);

    // Create a Blob with the CSV data
    const blob = new Blob([csv], { type: "text/csv;charset=utf-8;" });

    // Create a download link and trigger the download
    const link = document.createElement("a");
    link.href = URL.createObjectURL(blob);
    link.download = `customers-${formatDate(new Date(), {
      year: "numeric",
      month: "numeric",
      day: "numeric",
    })}.csv`;
    link.click();
  };

  const filterLength = [...searchParams.entries()]
    ?.map((e) => e[0])
    ?.filter((e) => e !== "page" && e !== "limit" && e !== "nameText")?.length;

  return (
    <>
      <div className="customers-wrap">
        <BreadCrumb pageName={formatMessage({ id: "customers" })} />
        <div className="ordersActions">
          <button
            className="btn btn-blue"
            onClick={() => {
              navigate("/customers/addCustomer");
            }}
          >
            <FormattedMessage id="addCustomer" />
          </button>
        </div>
        <div className="card-head mb-2">
          <div>
            <h4>
              <FormattedMessage id="customerGroups" />
            </h4>
          </div>
        </div>
        {/* <GroupsList /> */}
        <div className="filter-toolbar">
          <div className="ordersServices">
            <Dropdown>
              <Dropdown.Toggle>
                <SettingIcon />
                <FormattedMessage id="exportCustomers" />
              </Dropdown.Toggle>
              <Dropdown.Menu>
                <Dropdown.Item onClick={exportToXLSX}>
                  <SettingIcon />
                  <FormattedMessage id="exportCustomersXlsx" />
                </Dropdown.Item>
                <Dropdown.Item onClick={exportToCSV}>
                  <SettingIcon />
                  <FormattedMessage id="exportCustomersCsv" />
                </Dropdown.Item>
              </Dropdown.Menu>
            </Dropdown>
          </div>
          <div className="filter-toolbar__left">
            <div className="filter-toolbar__action">
              <button className="btn btn-black" onClick={filterToggle}>
                <img src={filterIcon} alt="settings" />
                <FormattedMessage id="filter" />
                {!!filterLength && (
                  <span className="filterLength">{filterLength}</span>
                )}
              </button>
            </div>
            <form
              className="filter-toolbar__search"
              onSubmit={handleSubmitSearch}
            >
              <input
                type="search"
                placeholder={formatMessage({ id: "customerSearch" })}
                className="form-control"
                {...registerSearch("txt")}
              />
              <EmptyButton />

              <button>
                <SearchIcon />
              </button>
            </form>
          </div>
          <ParamsFilters
            href="/customers"
            countries={countries}
            cities={cities}
            areas={areas}
            groups={groups}
          />
        </div>
        <Table data={users} />
        <CustomersFilterSidebar
          searchParams={searchParams}
          filtersObj={{
            gender: {
              type: "radio",
              data: ["male", "female"],
            },

            countries: {
              type: "none",
              data: countries,
              className: "border-bottom-0",
            },
            cities: {
              type: "none",
              data: cities,
              className: "border-bottom-0",
            },
            areas: {
              type: "areas",
              data: areas,
              // ?.filter(
              //   (a) => getId(a?.city) === searchParams?.get("city")
              // ),
            },
            groups: {
              type: "checkbox",
              data: groups,
            },
            createdAt: {
              type: "dateRange",
            },
            tags: {
              type: "checkbox",
              data: tags,
            },
          }}
        />
        <div className="overlay-g" onClick={filterToggle}></div>
        <BootyPagination metaData={metadata} />
        {loading && <StickyLoader fill="#FC6B14" />}
      </div>
    </>
  );
};

export default Customers;
