import server from "./server";

export const getBlogsApi = async (payload) => {
  const response = await server().get(`/blogs${payload}`);
  return response.data;
};

export const getSingleBlogApi = async (id) => {
  const response = await server().get(`/blogs/${id}`);
  return response.data;
};

export const addBlogApi = async (payload) => {
  const response = await server().post("/blogs", payload);
  return response.data;
};

export const editBlogApi = async ({ id, data }) => {
  const response = await server().put(`/blogs/${id}`, data);
  return response.data;
};

export const deleteBlogApi = async (id) => {
  const response = await server().delete(`/blogs/${id}`);
  return response.data;
};

export const getTagsApi = async () => {
  const response = await server().get("/blogs/getAllTag");
  return response.data;
};
