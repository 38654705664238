import { v4 } from "uuid";
import { ReactComponent as ReportIcon } from "assets/svgs/reports.svg";

const deviceTypes = { desktop: "desktop", mobile: "mobile" };

export const sidebarData = [
  {
    id: v4(),
    to: "/",
    text: "home",
    storeNeeded: false,
    svg: (
      <svg
        xmlns="http://www.w3.org/2000/svg"
        width="20"
        height="20"
        fill="#a3aed0"
        className="bi bi-speedometer2"
        viewBox="0 0 16 16"
      >
        <path d="M8 4a.5.5 0 0 1 .5.5V6a.5.5 0 0 1-1 0V4.5A.5.5 0 0 1 8 4zM3.732 5.732a.5.5 0 0 1 .707 0l.915.914a.5.5 0 1 1-.708.708l-.914-.915a.5.5 0 0 1 0-.707zM2 10a.5.5 0 0 1 .5-.5h1.586a.5.5 0 0 1 0 1H2.5A.5.5 0 0 1 2 10zm9.5 0a.5.5 0 0 1 .5-.5h1.5a.5.5 0 0 1 0 1H12a.5.5 0 0 1-.5-.5zm.754-4.246a.389.389 0 0 0-.527-.02L7.547 9.31a.91.91 0 1 0 1.302 1.258l3.434-4.297a.389.389 0 0 0-.029-.518z" />
        <path
          fillRule="evenodd"
          d="M0 10a8 8 0 1 1 15.547 2.661c-.442 1.253-1.845 1.602-2.932 1.25C11.309 13.488 9.475 13 8 13c-1.474 0-3.31.488-4.615.911-1.087.352-2.49.003-2.932-1.25A7.988 7.988 0 0 1 0 10zm8-7a7 7 0 0 0-6.603 9.329c.203.575.923.876 1.68.63C4.397 12.533 6.358 12 8 12s3.604.532 4.923.96c.757.245 1.477-.056 1.68-.631A7 7 0 0 0 8 3z"
        />
      </svg>
    ),
  },

  {
    id: v4(),
    to: "/products",
    text: "products",
    storeNeeded: true,

    svg: (
      <svg
        xmlns="http://www.w3.org/2000/svg"
        width="20"
        height="20"
        fill="#a3aed0"
        className="bi bi-bag-check"
        viewBox="0 0 16 16"
      >
        <path
          fillRule="evenodd"
          d="M10.854 8.146a.5.5 0 0 1 0 .708l-3 3a.5.5 0 0 1-.708 0l-1.5-1.5a.5.5 0 0 1 .708-.708L7.5 10.793l2.646-2.647a.5.5 0 0 1 .708 0z"
        />
        <path d="M8 1a2.5 2.5 0 0 1 2.5 2.5V4h-5v-.5A2.5 2.5 0 0 1 8 1zm3.5 3v-.5a3.5 3.5 0 1 0-7 0V4H1v10a2 2 0 0 0 2 2h10a2 2 0 0 0 2-2V4h-3.5zM2 5h12v9a1 1 0 0 1-1 1H3a1 1 0 0 1-1-1V5z" />
      </svg>
    ),
  },

  {
    id: v4(),
    to: "/coupons",
    text: "coupons",
    storeNeeded: true,

    svg: (
      <svg
        width="20"
        height="20"
        fill="#a3aed0"
        viewBox="0 0 24 24"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path d="M12 22.75C11.21 22.75 10.46 22.35 9.94 21.64L8.93 20.29C8.72 20.01 8.44 19.85 8.14 19.83C7.84 19.81 7.54 19.95 7.3 20.2L6.73 19.7L7.28 20.21C5.83 21.75 4.73 21.63 4.2 21.42C3.66 21.21 2.75 20.52 2.75 18.3V7.04C2.75 2.6 4.03 1.25 8.22 1.25H15.77C19.96 1.25 21.24 2.6 21.24 7.04V18.3C21.24 20.51 20.33 21.2 19.79 21.42C19.26 21.63 18.16 21.75 16.71 20.21C16.47 19.95 16.16 19.82 15.86 19.84C15.56 19.86 15.27 20.02 15.06 20.3L14.05 21.65C13.54 22.35 12.79 22.75 12 22.75ZM8.08 18.33C8.12 18.33 8.17 18.33 8.21 18.33C8.95 18.37 9.65 18.76 10.12 19.39L11.14 20.74C11.63 21.39 12.38 21.39 12.87 20.74L13.88 19.39C14.35 18.76 15.05 18.37 15.8 18.33C16.55 18.29 17.28 18.6 17.82 19.18C18.58 19.99 19.07 20.09 19.25 20.02C19.49 19.92 19.75 19.34 19.75 18.3V7.04C19.75 3.43 19.12 2.75 15.78 2.75H8.22C4.88 2.75 4.25 3.43 4.25 7.04V18.3C4.25 19.35 4.51 19.93 4.75 20.02C4.93 20.09 5.42 19.99 6.18 19.18C6.7 18.63 7.38 18.33 8.08 18.33Z" />
        <path d="M15 14C14.44 14 14 13.55 14 13C14 12.45 14.45 12 15 12C15.55 12 16 12.45 16 13C16 13.55 15.56 14 15 14Z" />
        <path d="M9 9C8.44 9 8 8.55 8 8C8 7.45 8.45 7 9 7C9.55 7 10 7.45 10 8C10 8.55 9.56 9 9 9Z" />
        <path d="M8.63031 14.55C8.44031 14.55 8.25031 14.48 8.10031 14.33C7.81031 14.04 7.81031 13.56 8.10031 13.27L14.8303 6.54001C15.1203 6.25001 15.6003 6.25001 15.8903 6.54001C16.1803 6.83001 16.1803 7.31001 15.8903 7.60001L9.16031 14.33C9.02031 14.48 8.83031 14.55 8.63031 14.55Z" />
      </svg>
    ),
  },

  {
    id: v4(),
    to: "/orders",
    text: "orders",
    storeNeeded: true,

    svg: (
      <svg
        xmlns="http://www.w3.org/2000/svg"
        width="20"
        height="20"
        fill="#a3aed0"
        className="bi bi-inboxes"
        viewBox="0 0 16 16"
      >
        <path d="M4.98 1a.5.5 0 0 0-.39.188L1.54 5H6a.5.5 0 0 1 .5.5 1.5 1.5 0 0 0 3 0A.5.5 0 0 1 10 5h4.46l-3.05-3.812A.5.5 0 0 0 11.02 1H4.98zm9.954 5H10.45a2.5 2.5 0 0 1-4.9 0H1.066l.32 2.562A.5.5 0 0 0 1.884 9h12.234a.5.5 0 0 0 .496-.438L14.933 6zM3.809.563A1.5 1.5 0 0 1 4.981 0h6.038a1.5 1.5 0 0 1 1.172.563l3.7 4.625a.5.5 0 0 1 .105.374l-.39 3.124A1.5 1.5 0 0 1 14.117 10H1.883A1.5 1.5 0 0 1 .394 8.686l-.39-3.124a.5.5 0 0 1 .106-.374L3.81.563zM.125 11.17A.5.5 0 0 1 .5 11H6a.5.5 0 0 1 .5.5 1.5 1.5 0 0 0 3 0 .5.5 0 0 1 .5-.5h5.5a.5.5 0 0 1 .496.562l-.39 3.124A1.5 1.5 0 0 1 14.117 16H1.883a1.5 1.5 0 0 1-1.489-1.314l-.39-3.124a.5.5 0 0 1 .121-.393zm.941.83.32 2.562a.5.5 0 0 0 .497.438h12.234a.5.5 0 0 0 .496-.438l.32-2.562H10.45a2.5 2.5 0 0 1-4.9 0H1.066z" />
      </svg>
    ),
  },

  // {
  //   id: v4(),
  //   to: "/clients",
  //   text: "clients",
  //   storeNeeded: true,

  //   svg: (
  //     <svg
  //       xmlns="http://www.w3.org/2000/svg"
  //       width="20"
  //       height="20"
  //       fill="#a3aed0"
  //       className="bi bi-people"
  //       viewBox="0 0 16 16"
  //     >
  //       <path d="M15 14s1 0 1-1-1-4-5-4-5 3-5 4 1 1 1 1h8zm-7.978-1A.261.261 0 0 1 7 12.996c.001-.264.167-1.03.76-1.72C8.312 10.629 9.282 10 11 10c1.717 0 2.687.63 3.24 1.276.593.69.758 1.457.76 1.72l-.008.002a.274.274 0 0 1-.014.002H7.022zM11 7a2 2 0 1 0 0-4 2 2 0 0 0 0 4zm3-2a3 3 0 1 1-6 0 3 3 0 0 1 6 0zM6.936 9.28a5.88 5.88 0 0 0-1.23-.247A7.35 7.35 0 0 0 5 9c-4 0-5 3-5 4 0 .667.333 1 1 1h4.216A2.238 2.238 0 0 1 5 13c0-1.01.377-2.042 1.09-2.904.243-.294.526-.569.846-.816zM4.92 10A5.493 5.493 0 0 0 4 13H1c0-.26.164-1.03.76-1.724.545-.636 1.492-1.256 3.16-1.275zM1.5 5.5a3 3 0 1 1 6 0 3 3 0 0 1-6 0zm3-2a2 2 0 1 0 0 4 2 2 0 0 0 0-4z" />
  //     </svg>
  //   ),
  // },

  // {
  //   id: v4(),
  //   to: "/reports",
  //   text: "reports",
  //   storeNeeded: true,

  //   svg: (
  //     <svg
  //       xmlns="http://www.w3.org/2000/svg"
  //       width="20"
  //       height="20"
  //       fill="#a3aed0"
  //       className="bi bi-bag-check"
  //       viewBox="0 0 16 16"
  //     >
  //       <path
  //         fillRule="evenodd"
  //         d="M10.854 8.146a.5.5 0 0 1 0 .708l-3 3a.5.5 0 0 1-.708 0l-1.5-1.5a.5.5 0 0 1 .708-.708L7.5 10.793l2.646-2.647a.5.5 0 0 1 .708 0z"
  //       />
  //       <path d="M8 1a2.5 2.5 0 0 1 2.5 2.5V4h-5v-.5A2.5 2.5 0 0 1 8 1zm3.5 3v-.5a3.5 3.5 0 1 0-7 0V4H1v10a2 2 0 0 0 2 2h10a2 2 0 0 0 2-2V4h-3.5zM2 5h12v9a1 1 0 0 1-1 1H3a1 1 0 0 1-1-1V5z" />
  //     </svg>
  //   ),
  // },

  {
    id: v4(),
    to: "/stores",
    text: "stores",
    storeNeeded: false,

    svg: (
      <svg
        xmlns="http://www.w3.org/2000/svg"
        width="20"
        height="20"
        fill="#a3aed0"
        className="bi bi-shop"
        viewBox="0 0 16 16"
      >
        <path d="M2.97 1.35A1 1 0 0 1 3.73 1h8.54a1 1 0 0 1 .76.35l2.609 3.044A1.5 1.5 0 0 1 16 5.37v.255a2.375 2.375 0 0 1-4.25 1.458A2.371 2.371 0 0 1 9.875 8 2.37 2.37 0 0 1 8 7.083 2.37 2.37 0 0 1 6.125 8a2.37 2.37 0 0 1-1.875-.917A2.375 2.375 0 0 1 0 5.625V5.37a1.5 1.5 0 0 1 .361-.976l2.61-3.045zm1.78 4.275a1.375 1.375 0 0 0 2.75 0 .5.5 0 0 1 1 0 1.375 1.375 0 0 0 2.75 0 .5.5 0 0 1 1 0 1.375 1.375 0 1 0 2.75 0V5.37a.5.5 0 0 0-.12-.325L12.27 2H3.73L1.12 5.045A.5.5 0 0 0 1 5.37v.255a1.375 1.375 0 0 0 2.75 0 .5.5 0 0 1 1 0zM1.5 8.5A.5.5 0 0 1 2 9v6h1v-5a1 1 0 0 1 1-1h3a1 1 0 0 1 1 1v5h6V9a.5.5 0 0 1 1 0v6h.5a.5.5 0 0 1 0 1H.5a.5.5 0 0 1 0-1H1V9a.5.5 0 0 1 .5-.5zM4 15h3v-5H4v5zm5-5a1 1 0 0 1 1-1h2a1 1 0 0 1 1 1v3a1 1 0 0 1-1 1h-2a1 1 0 0 1-1-1v-3zm3 0h-2v3h2v-3z" />
      </svg>
    ),
    // children: [
    //   {
    //     id: v4(),
    //     to: "/stores/addstore",
    //     text: "addStore",
    //     svg: (
    //       <svg
    //         width="20"
    //         height="19"
    //         viewBox="0 0 20 19"
    //         xmlns="http://www.w3.org/2000/svg"
    //       >
    //         <path
    //           d="M18 18.9103H2C0.89543 18.9103 0 17.9957 0 16.8675V2.56837C0 1.4402 0.89543 0.525635 2 0.525635H18C19.1046 0.525635 20 1.4402 20 2.56837V16.8675C20 17.9957 19.1046 18.9103 18 18.9103ZM2 4.6111V16.8675H18V4.6111H2ZM16 14.8248H10V12.782H16V14.8248ZM5.414 14.8248L4 13.3806L6.293 11.0396L4 8.69658L5.414 7.25236L9.12 11.0386L5.415 14.8248H5.414Z"
    //           fill="#A3AED0"
    //         />
    //       </svg>
    //     ),
    //   },
    // ],
  },

  {
    id: v4(),
    to: "/categories",
    text: "categories",
    storeNeeded: true,

    svg: (
      <svg
        xmlns="http://www.w3.org/2000/svg"
        width="20"
        height="20"
        fill="#a3aed0"
        className="bi bi-tags"
        viewBox="0 0 16 16"
      >
        <path d="M3 2v4.586l7 7L14.586 9l-7-7H3zM2 2a1 1 0 0 1 1-1h4.586a1 1 0 0 1 .707.293l7 7a1 1 0 0 1 0 1.414l-4.586 4.586a1 1 0 0 1-1.414 0l-7-7A1 1 0 0 1 2 6.586V2z" />
        <path d="M5.5 5a.5.5 0 1 1 0-1 .5.5 0 0 1 0 1zm0 1a1.5 1.5 0 1 0 0-3 1.5 1.5 0 0 0 0 3zM1 7.086a1 1 0 0 0 .293.707L8.75 15.25l-.043.043a1 1 0 0 1-1.414 0l-7-7A1 1 0 0 1 0 7.586V3a1 1 0 0 1 1-1v5.086z" />
      </svg>
    ),
    // children: [
    //   {
    //     id: v4(),
    //     to: "/categories/addcategory",
    //     text: "addCategory",
    //     svg: (
    //       <svg
    //         width="20"
    //         height="19"
    //         viewBox="0 0 20 19"
    //         xmlns="http://www.w3.org/2000/svg"
    //       >
    //         <path
    //           d="M18 18.9103H2C0.89543 18.9103 0 17.9957 0 16.8675V2.56837C0 1.4402 0.89543 0.525635 2 0.525635H18C19.1046 0.525635 20 1.4402 20 2.56837V16.8675C20 17.9957 19.1046 18.9103 18 18.9103ZM2 4.6111V16.8675H18V4.6111H2ZM16 14.8248H10V12.782H16V14.8248ZM5.414 14.8248L4 13.3806L6.293 11.0396L4 8.69658L5.414 7.25236L9.12 11.0386L5.415 14.8248H5.414Z"
    //           fill="#A3AED0"
    //         />
    //       </svg>
    //     ),
    //   },
    // ],
  },

  {
    id: v4(),
    to: "/brands",
    text: "brands",
    storeNeeded: true,

    svg: (
      <svg
        xmlns="http://www.w3.org/2000/svg"
        width="20"
        height="20"
        fill="#a3aed0"
        className="bi bi-apple"
        viewBox="0 0 16 16"
      >
        <path d="M11.182.008C11.148-.03 9.923.023 8.857 1.18c-1.066 1.156-.902 2.482-.878 2.516.024.034 1.52.087 2.475-1.258.955-1.345.762-2.391.728-2.43zm3.314 11.733c-.048-.096-2.325-1.234-2.113-3.422.212-2.189 1.675-2.789 1.698-2.854.023-.065-.597-.79-1.254-1.157a3.692 3.692 0 0 0-1.563-.434c-.108-.003-.483-.095-1.254.116-.508.139-1.653.589-1.968.607-.316.018-1.256-.522-2.267-.665-.647-.125-1.333.131-1.824.328-.49.196-1.422.754-2.074 2.237-.652 1.482-.311 3.83-.067 4.56.244.729.625 1.924 1.273 2.796.576.984 1.34 1.667 1.659 1.899.319.232 1.219.386 1.843.067.502-.308 1.408-.485 1.766-.472.357.013 1.061.154 1.782.539.571.197 1.111.115 1.652-.105.541-.221 1.324-1.059 2.238-2.758.347-.79.505-1.217.473-1.282z" />
        <path d="M11.182.008C11.148-.03 9.923.023 8.857 1.18c-1.066 1.156-.902 2.482-.878 2.516.024.034 1.52.087 2.475-1.258.955-1.345.762-2.391.728-2.43zm3.314 11.733c-.048-.096-2.325-1.234-2.113-3.422.212-2.189 1.675-2.789 1.698-2.854.023-.065-.597-.79-1.254-1.157a3.692 3.692 0 0 0-1.563-.434c-.108-.003-.483-.095-1.254.116-.508.139-1.653.589-1.968.607-.316.018-1.256-.522-2.267-.665-.647-.125-1.333.131-1.824.328-.49.196-1.422.754-2.074 2.237-.652 1.482-.311 3.83-.067 4.56.244.729.625 1.924 1.273 2.796.576.984 1.34 1.667 1.659 1.899.319.232 1.219.386 1.843.067.502-.308 1.408-.485 1.766-.472.357.013 1.061.154 1.782.539.571.197 1.111.115 1.652-.105.541-.221 1.324-1.059 2.238-2.758.347-.79.505-1.217.473-1.282z" />
      </svg>
    ),
  },
  {
    id: v4(),
    to: "/customers",
    text: "customers",
    storeNeeded: true,
    svg: (
      <svg
        xmlns="http://www.w3.org/2000/svg"
        width="16"
        height="16"
        fill="#a3aed0"
        className="bi bi-people-fill"
        viewBox="0 0 16 16"
      >
        <path d="M7 14s-1 0-1-1 1-4 5-4 5 3 5 4-1 1-1 1H7Zm4-6a3 3 0 1 0 0-6 3 3 0 0 0 0 6Zm-5.784 6A2.238 2.238 0 0 1 5 13c0-1.355.68-2.75 1.936-3.72A6.325 6.325 0 0 0 5 9c-4 0-5 3-5 4s1 1 1 1h4.216ZM4.5 8a2.5 2.5 0 1 0 0-5 2.5 2.5 0 0 0 0 5Z" />
      </svg>
    ),
  },
  {
    id: v4(),
    to: "/productsReviews",
    text: "productsReviews",
    storeNeeded: true,
    svg: (
      <svg
        xmlns="http://www.w3.org/2000/svg"
        width="16"
        height="16"
        fill="#a3aed0"
        className="bi bi-people-fill"
        viewBox="0 0 16 16"
      >
        <path d="M7 14s-1 0-1-1 1-4 5-4 5 3 5 4-1 1-1 1H7Zm4-6a3 3 0 1 0 0-6 3 3 0 0 0 0 6Zm-5.784 6A2.238 2.238 0 0 1 5 13c0-1.355.68-2.75 1.936-3.72A6.325 6.325 0 0 0 5 9c-4 0-5 3-5 4s1 1 1 1h4.216ZM4.5 8a2.5 2.5 0 1 0 0-5 2.5 2.5 0 0 0 0 5Z" />
      </svg>
    ),
  },
  {
    id: v4(),
    to: "/blogs",
    text: "blogs",
    storeNeeded: true,

    svg: (
      <svg
        xmlns="http://www.w3.org/2000/svg"
        width="20"
        height="20"
        fill="#a3aed0"
        className="bi bi-apple"
        viewBox="0 0 16 16"
      >
        <path d="M7 4.25a.75.75 0 1 1-1.5 0 .75.75 0 0 1 1.5 0zm-.861 1.542 1.33.886 1.854-1.855a.25.25 0 0 1 .289-.047l1.888.974V7.5a.5.5 0 0 1-.5.5H5a.5.5 0 0 1-.5-.5V7s1.54-1.274 1.639-1.208zM5 9a.5.5 0 0 0 0 1h6a.5.5 0 0 0 0-1H5zm0 2a.5.5 0 0 0 0 1h3a.5.5 0 0 0 0-1H5z" />
        <path d="M2 2a2 2 0 0 1 2-2h8a2 2 0 0 1 2 2v12a2 2 0 0 1-2 2H4a2 2 0 0 1-2-2V2zm10-1H4a1 1 0 0 0-1 1v12a1 1 0 0 0 1 1h8a1 1 0 0 0 1-1V2a1 1 0 0 0-1-1z" />
      </svg>
    ),
    children: [
      {
        id: v4(),
        to: "/blogs/categories",
        text: "blogCategories",
        svg: (
          <svg
            width="20"
            height="19"
            viewBox="0 0 20 19"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              d="M18 18.9103H2C0.89543 18.9103 0 17.9957 0 16.8675V2.56837C0 1.4402 0.89543 0.525635 2 0.525635H18C19.1046 0.525635 20 1.4402 20 2.56837V16.8675C20 17.9957 19.1046 18.9103 18 18.9103ZM2 4.6111V16.8675H18V4.6111H2ZM16 14.8248H10V12.782H16V14.8248ZM5.414 14.8248L4 13.3806L6.293 11.0396L4 8.69658L5.414 7.25236L9.12 11.0386L5.415 14.8248H5.414Z"
              fill="#A3AED0"
            />
          </svg>
        ),
      },
    ],
  },
  {
    id: v4(),
    to: "/faqs",
    text: "faqs",
    storeNeeded: true,
    // children: [
    //   {
    //     id: v4(),
    //     to: "/faqs/categories",
    //     text: "faqsCategories",
    //     svg: (
    //       <svg
    //         width="20"
    //         height="19"
    //         viewBox="0 0 20 19"
    //         xmlns="http://www.w3.org/2000/svg"
    //       >
    //         <path
    //           d="M18 18.9103H2C0.89543 18.9103 0 17.9957 0 16.8675V2.56837C0 1.4402 0.89543 0.525635 2 0.525635H18C19.1046 0.525635 20 1.4402 20 2.56837V16.8675C20 17.9957 19.1046 18.9103 18 18.9103ZM2 4.6111V16.8675H18V4.6111H2ZM16 14.8248H10V12.782H16V14.8248ZM5.414 14.8248L4 13.3806L6.293 11.0396L4 8.69658L5.414 7.25236L9.12 11.0386L5.415 14.8248H5.414Z"
    //           fill="#A3AED0"
    //         />
    //       </svg>
    //     ),
    //   },
    // ],
    svg: (
      <svg
        xmlns="http://www.w3.org/2000/svg"
        width="20"
        height="20"
        fill="#a3aed0"
        className="bi bi-apple"
        viewBox="0 0 16 16"
      >
        <path d="M7 4.25a.75.75 0 1 1-1.5 0 .75.75 0 0 1 1.5 0zm-.861 1.542 1.33.886 1.854-1.855a.25.25 0 0 1 .289-.047l1.888.974V7.5a.5.5 0 0 1-.5.5H5a.5.5 0 0 1-.5-.5V7s1.54-1.274 1.639-1.208zM5 9a.5.5 0 0 0 0 1h6a.5.5 0 0 0 0-1H5zm0 2a.5.5 0 0 0 0 1h3a.5.5 0 0 0 0-1H5z" />
        <path d="M2 2a2 2 0 0 1 2-2h8a2 2 0 0 1 2 2v12a2 2 0 0 1-2 2H4a2 2 0 0 1-2-2V2zm10-1H4a1 1 0 0 0-1 1v12a1 1 0 0 0 1 1h8a1 1 0 0 0 1-1V2a1 1 0 0 0-1-1z" />
      </svg>
    ),
  },
  { separator: true },
  {
    id: v4(),
    to: "/visits-reports",
    text: "visits",
    storeNeeded: true,
    svg: <ReportIcon />,
    // children: [
    //   {
    //     id: v4(),
    //     to: "/faqs/categories",
    //     text: "faqsCategories",
    //     svg: (
    //       <svg
    //         width="20"
    //         height="19"
    //         viewBox="0 0 20 19"
    //         xmlns="http://www.w3.org/2000/svg"
    //       >
    //         <path
    //           d="M18 18.9103H2C0.89543 18.9103 0 17.9957 0 16.8675V2.56837C0 1.4402 0.89543 0.525635 2 0.525635H18C19.1046 0.525635 20 1.4402 20 2.56837V16.8675C20 17.9957 19.1046 18.9103 18 18.9103ZM2 4.6111V16.8675H18V4.6111H2ZM16 14.8248H10V12.782H16V14.8248ZM5.414 14.8248L4 13.3806L6.293 11.0396L4 8.69658L5.414 7.25236L9.12 11.0386L5.415 14.8248H5.414Z"
    //           fill="#A3AED0"
    //         />
    //       </svg>
    //     ),
    //   },
    // ],
  },
  { separator: true },
  {
    id: v4(),
    to: `/themeTemplates?deviceType=${deviceTypes?.desktop}`,
    text: "themeTemplates",
    storeNeeded: true,
    svg: (
      <svg
        xmlns="http://www.w3.org/2000/svg"
        width="20"
        height="20"
        fill="#a3aed0"
        className="bi bi-layout-text-window"
        viewBox="0 0 16 16"
      >
        <path d="M3 6.5a.5.5 0 0 1 .5-.5h5a.5.5 0 0 1 0 1h-5a.5.5 0 0 1-.5-.5zm0 3a.5.5 0 0 1 .5-.5h5a.5.5 0 0 1 0 1h-5a.5.5 0 0 1-.5-.5zm.5 2.5a.5.5 0 0 0 0 1h5a.5.5 0 0 0 0-1h-5z" />
        <path d="M2 0a2 2 0 0 0-2 2v12a2 2 0 0 0 2 2h12a2 2 0 0 0 2-2V2a2 2 0 0 0-2-2H2zm12 1a1 1 0 0 1 1 1v1H1V2a1 1 0 0 1 1-1h12zm1 3v10a1 1 0 0 1-1 1h-2V4h3zm-4 0v11H2a1 1 0 0 1-1-1V4h10z" />
      </svg>
    ),
  },
  {
    id: v4(),
    to: `/themeTemplates?deviceType=${deviceTypes?.mobile}`,
    text: "themeTemplatesMobile",
    storeNeeded: true,
    svg: (
      <svg
        xmlns="http://www.w3.org/2000/svg"
        width="20"
        height="20"
        fill="#a3aed0"
        className="bi bi-layout-text-window"
        viewBox="0 0 16 16"
      >
        <path d="M3 6.5a.5.5 0 0 1 .5-.5h5a.5.5 0 0 1 0 1h-5a.5.5 0 0 1-.5-.5zm0 3a.5.5 0 0 1 .5-.5h5a.5.5 0 0 1 0 1h-5a.5.5 0 0 1-.5-.5zm.5 2.5a.5.5 0 0 0 0 1h5a.5.5 0 0 0 0-1h-5z" />
        <path d="M2 0a2 2 0 0 0-2 2v12a2 2 0 0 0 2 2h12a2 2 0 0 0 2-2V2a2 2 0 0 0-2-2H2zm12 1a1 1 0 0 1 1 1v1H1V2a1 1 0 0 1 1-1h12zm1 3v10a1 1 0 0 1-1 1h-2V4h3zm-4 0v11H2a1 1 0 0 1-1-1V4h10z" />
      </svg>
    ),
  },

  {
    id: v4(),
    to: `/storethemes?deviceType=${deviceTypes?.desktop}`,
    text: "storeThemes",
    storeNeeded: true,

    svg: (
      <svg
        xmlns="http://www.w3.org/2000/svg"
        width="20"
        height="20"
        fill="#a3aed0"
        className="bi bi-brush"
        viewBox="0 0 16 16"
      >
        <path d="M15.825.12a.5.5 0 0 1 .132.584c-1.53 3.43-4.743 8.17-7.095 10.64a6.067 6.067 0 0 1-2.373 1.534c-.018.227-.06.538-.16.868-.201.659-.667 1.479-1.708 1.74a8.118 8.118 0 0 1-3.078.132 3.659 3.659 0 0 1-.562-.135 1.382 1.382 0 0 1-.466-.247.714.714 0 0 1-.204-.288.622.622 0 0 1 .004-.443c.095-.245.316-.38.461-.452.394-.197.625-.453.867-.826.095-.144.184-.297.287-.472l.117-.198c.151-.255.326-.54.546-.848.528-.739 1.201-.925 1.746-.896.126.007.243.025.348.048.062-.172.142-.38.238-.608.261-.619.658-1.419 1.187-2.069 2.176-2.67 6.18-6.206 9.117-8.104a.5.5 0 0 1 .596.04zM4.705 11.912a1.23 1.23 0 0 0-.419-.1c-.246-.013-.573.05-.879.479-.197.275-.355.532-.5.777l-.105.177c-.106.181-.213.362-.32.528a3.39 3.39 0 0 1-.76.861c.69.112 1.736.111 2.657-.12.559-.139.843-.569.993-1.06a3.122 3.122 0 0 0 .126-.75l-.793-.792zm1.44.026c.12-.04.277-.1.458-.183a5.068 5.068 0 0 0 1.535-1.1c1.9-1.996 4.412-5.57 6.052-8.631-2.59 1.927-5.566 4.66-7.302 6.792-.442.543-.795 1.243-1.042 1.826-.121.288-.214.54-.275.72v.001l.575.575zm-4.973 3.04.007-.005a.031.031 0 0 1-.007.004zm3.582-3.043.002.001h-.002z" />
      </svg>
    ),
  },
  {
    id: v4(),
    to: `/storethemes?deviceType=${deviceTypes?.mobile}`,
    text: "storeThemesMobile",
    storeNeeded: true,

    svg: (
      <svg
        width="20"
        height="20"
        fill="#a3aed0"
        viewBox="0 0 24 24"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          d="M15 22.75H9C4.59 22.75 3.25 21.41 3.25 17V7C3.25 2.59 4.59 1.25 9 1.25H15C19.41 1.25 20.75 2.59 20.75 7V17C20.75 21.41 19.41 22.75 15 22.75ZM9 2.75C5.42 2.75 4.75 3.43 4.75 7V17C4.75 20.57 5.42 21.25 9 21.25H15C18.58 21.25 19.25 20.57 19.25 17V7C19.25 3.43 18.58 2.75 15 2.75H9Z"
          fill="#a3aed0"
        />
        <path
          d="M14 6.25H10C9.59 6.25 9.25 5.91 9.25 5.5C9.25 5.09 9.59 4.75 10 4.75H14C14.41 4.75 14.75 5.09 14.75 5.5C14.75 5.91 14.41 6.25 14 6.25Z"
          fill="#a3aed0"
        />
        <path
          d="M12.0002 19.86C10.7302 19.86 9.7002 18.83 9.7002 17.56C9.7002 16.29 10.7302 15.26 12.0002 15.26C13.2702 15.26 14.3002 16.29 14.3002 17.56C14.3002 18.83 13.2702 19.86 12.0002 19.86ZM12.0002 16.75C11.5602 16.75 11.2002 17.11 11.2002 17.55C11.2002 17.99 11.5602 18.35 12.0002 18.35C12.4402 18.35 12.8002 17.99 12.8002 17.55C12.8002 17.11 12.4402 16.75 12.0002 16.75Z"
          fill="#a3aed0"
        />
      </svg>
    ),
  },
  { separator: true },

  // {
  //   id: v4(),
  //   to: "/pages",
  //   text: "pages",
  //   storeNeeded: true,
  //   svg: (
  //     <svg
  //       xmlns="http://www.w3.org/2000/svg"
  //       width="20"
  //       height="20"
  //       fill="#a3aed0"
  //       className="bi bi-card-text"
  //       viewBox="0 0 16 16"
  //     >
  //       <path d="M14.5 3a.5.5 0 0 1 .5.5v9a.5.5 0 0 1-.5.5h-13a.5.5 0 0 1-.5-.5v-9a.5.5 0 0 1 .5-.5h13zm-13-1A1.5 1.5 0 0 0 0 3.5v9A1.5 1.5 0 0 0 1.5 14h13a1.5 1.5 0 0 0 1.5-1.5v-9A1.5 1.5 0 0 0 14.5 2h-13z" />
  //       <path d="M3 5.5a.5.5 0 0 1 .5-.5h9a.5.5 0 0 1 0 1h-9a.5.5 0 0 1-.5-.5zM3 8a.5.5 0 0 1 .5-.5h9a.5.5 0 0 1 0 1h-9A.5.5 0 0 1 3 8zm0 2.5a.5.5 0 0 1 .5-.5h6a.5.5 0 0 1 0 1h-6a.5.5 0 0 1-.5-.5z" />
  //     </svg>
  //   ),
  // },

  {
    id: v4(),
    to: "/settings",
    text: "settings",
    storeNeeded: true,
    svg: (
      <svg
        xmlns="http://www.w3.org/2000/svg"
        width="20"
        height="20"
        fill="#a3aed0"
        className="bi bi-gear"
        viewBox="0 0 16 16"
      >
        <path d="M8 4.754a3.246 3.246 0 1 0 0 6.492 3.246 3.246 0 0 0 0-6.492zM5.754 8a2.246 2.246 0 1 1 4.492 0 2.246 2.246 0 0 1-4.492 0z" />
        <path d="M9.796 1.343c-.527-1.79-3.065-1.79-3.592 0l-.094.319a.873.873 0 0 1-1.255.52l-.292-.16c-1.64-.892-3.433.902-2.54 2.541l.159.292a.873.873 0 0 1-.52 1.255l-.319.094c-1.79.527-1.79 3.065 0 3.592l.319.094a.873.873 0 0 1 .52 1.255l-.16.292c-.892 1.64.901 3.434 2.541 2.54l.292-.159a.873.873 0 0 1 1.255.52l.094.319c.527 1.79 3.065 1.79 3.592 0l.094-.319a.873.873 0 0 1 1.255-.52l.292.16c1.64.893 3.434-.902 2.54-2.541l-.159-.292a.873.873 0 0 1 .52-1.255l.319-.094c1.79-.527 1.79-3.065 0-3.592l-.319-.094a.873.873 0 0 1-.52-1.255l.16-.292c.893-1.64-.902-3.433-2.541-2.54l-.292.159a.873.873 0 0 1-1.255-.52l-.094-.319zm-2.633.283c.246-.835 1.428-.835 1.674 0l.094.319a1.873 1.873 0 0 0 2.693 1.115l.291-.16c.764-.415 1.6.42 1.184 1.185l-.159.292a1.873 1.873 0 0 0 1.116 2.692l.318.094c.835.246.835 1.428 0 1.674l-.319.094a1.873 1.873 0 0 0-1.115 2.693l.16.291c.415.764-.42 1.6-1.185 1.184l-.291-.159a1.873 1.873 0 0 0-2.693 1.116l-.094.318c-.246.835-1.428.835-1.674 0l-.094-.319a1.873 1.873 0 0 0-2.692-1.115l-.292.16c-.764.415-1.6-.42-1.184-1.185l.159-.291A1.873 1.873 0 0 0 1.945 8.93l-.319-.094c-.835-.246-.835-1.428 0-1.674l.319-.094A1.873 1.873 0 0 0 3.06 4.377l-.16-.292c-.415-.764.42-1.6 1.185-1.184l.292.159a1.873 1.873 0 0 0 2.692-1.115l.094-.319z" />
      </svg>
    ),
  },
];
